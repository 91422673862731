<template>
  <div v-show="app.ready" class="flex flex-col justify-center md:space-y-20">
    <div
      class="flex-none mx-auto w-full flex items-center justify-center h-[100px]"
      :style="{ backgroundColor: 'var(--background-color)' }"
    >
      <div
        v-if="hasCustomLogo"
        :style="{
          backgroundImage: `url(${whitelabel.get('logoUrl')}`,
        }"
        class="bg-cover bg-center mx-auto h-16 whitelabel-logo"
      />
      <PulseIconLogo v-else name="logo" />
    </div>
    <div class="flex-1 flex md:items-center md:pt-0 pb-8 justify-center main-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';

import { useWhiteLabelStore, useApplicationStore } from '~/store';

const whitelabel = useWhiteLabelStore();
const app = useApplicationStore();

const { hasCustomLogo } = storeToRefs(whitelabel);

useHead({
  link: [
    {
      href: whitelabel.get('faviconUrl'),
      rel: 'icon',
    },
  ],
  title: whitelabel.get('pageTitle') || 'OrderProtection.com - Claims',
});
</script>

<style>
.whitelabel-logo {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: var(--logo-width-mobile, 115px);
}

.main-content {
  height: calc(100vh - 100px);
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .whitelabel-logo {
    width: var(--logo-width-desktop, 115px);
  }
}
</style>
